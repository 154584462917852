import { useEffect, useState, useRef, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/CharacterChat.module.scss';
import LabeledCheckbox from '../components/Checkbox/LabeledCheckbox';
import axiosRequest from '../utils/request';
import { useSelector } from 'react-redux';
import Typewriter from 'typewriter-effect';

import updateImageUrl from '../utils/updateImageUrl';

import CustomDropdown from '../components/Dropdown';
import SubscribeModal from '../components/SubscribeModal';

import ModalStyle from '../components/SubscribeModal/SubModal.module.scss'

import { toast } from 'react-toastify';

function ConfirmModal({ isOpen, onClose, onConfirm, title, message }) {
  if (!isOpen) return null;


  return (
    <div className={ModalStyle.modalOverlay}>
    <div className={ModalStyle.modalContent}>
      <div className={ModalStyle.modalContentInner}>
        <h2 className={ModalStyle.modalTitle}>{title}</h2>
        <p className={ModalStyle.modalText}>
        {message}
        </p>
        <div className={ModalStyle.modalActions}>
          <button className={`${ModalStyle.button} ${ModalStyle.cancelButton}`} onClick={onClose}>
            Cancel
          </button>
          <button 
            className={`${ModalStyle.button} 
            ${ModalStyle.confirmButton}`} 
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default function CharacterChat() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [messages, setMessages] = useState([]);
  const [character, setCharacter] = useState(null);
  const [nsfw, setNSFW] = useState(true);
  const [chatId, setChatId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [newMessage, setNewMessage] = useState(null);

  const [msgType, setMsgType] = useState('Chat');
  const msgOptions = ['Chat', 'Image'];



  const chatBoxRef = useRef(null);
  const { charactername } = useParams();

  const user = useSelector(state => state.userData);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchCharacterAndChat = async () => {
      try {
        setLoading(true);
        const jwt = localStorage.getItem('jwt');
        let promises = [
          axiosRequest('get', `character/${charactername}`, null, jwt),
          axiosRequest('get', `chats/${charactername}/messages`, null, jwt)
        ];
        let [characterResponse, messagesResponse] = await Promise.all(promises);
        setCharacter(characterResponse.data.character);

        if (messagesResponse.data.length === 1) {
          // If there's only one message, set it as newMessage to be typed out
          const firstMessage = messagesResponse.data[0];
          setNewMessage({ content: firstMessage.content, senderType: 'character' });
          setMessages([]);
        } else {
          // If there are multiple messages, just set them all in the messages state
          setMessages(messagesResponse.data.reverse());
        }

        setChatId(messagesResponse.data[0]?.chatId);
      } catch (err) {
        setError("Failed to load chat. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (charactername) {
      fetchCharacterAndChat();
    }
  }, [charactername]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, newMessage]);

  useEffect(() => {
    const metaViewport = document.querySelector('meta[name=viewport]');
    const viewportContent = metaViewport.getAttribute('content');
    metaViewport.setAttribute('content', `${viewportContent}, maximum-scale=1, user-scalable=0`);

    return () => {
      metaViewport.setAttribute('content', viewportContent);
    };
  }, []);


  const formatMessage = (content) => {
    return content.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        {index < content.split('\n').length - 1 && <br />}
      </Fragment>
    ));
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
  
    if (user.subscriptionStatus == 'inactive' && messages.length > 1) {
      setShowSubModal(true)
      return;
    };
  
    const messageInput = e.target.elements.messageInput;
    const newUserMessage = messageInput.value.trim();
    if (newUserMessage && chatId) {
      try {
        const jwt = localStorage.getItem('jwt');
        setMessages(prevMessages => [
          { content: newUserMessage, senderType: 'user' },
          ...prevMessages,
        ]);
        messageInput.value = '';
        setIsTyping(true);
  
        const messageData = {
          content: newUserMessage,
          description: character.description,
        };
  
        // Only include persona if one is selected
        if (selectedPersona) {
          messageData.persona = selectedPersona;
        }
  
        const response = await axiosRequest('post', `chats/${chatId}/messages`, messageData, jwt);
        setIsTyping(false);
        if (response.data.aiMessage) {
          setNewMessage({ content: response.data.aiMessage.content, senderType: 'character' });
        } else {
          toast.error(response.data.message)
        }
      } catch (err) {
        console.error("Failed to send message:", err);
        setIsTyping(false);
      }
    }
  };

  const handleTypingComplete = () => {
    if (newMessage) {
      setMessages(prevMessages => [newMessage, ...prevMessages]);
      setNewMessage(null);
    }
  };

  const [showContinueButton, setShowContinueButton] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);


  useEffect(() => {
    if (messages.length > 0 && messages[0].senderType === 'character' && !isTyping && !newMessage) {
      setShowContinueButton(true);
    } else {
      setShowContinueButton(false);
    }
  }, [messages, isTyping, newMessage]);

  const handleContinue = async () => {
    if (chatId) {
      try {
        if (user.subscriptionStatus == 'inactive' && messages.length > 1) {
          setShowSubModal(true)
          return;
        };

        const jwt = localStorage.getItem('jwt');
        setIsTyping(true);
        setShowContinueButton(false);
        const response = await axiosRequest('post', `chats/${chatId}/messages`, { content: "go on...", description: character.description, isContinue: true }, jwt);
        setIsTyping(false);
        setNewMessage({ content: response.data.aiMessage.content, senderType: 'character' });
      } catch (err) {
        console.error("Failed to send continue message:", err);
        setIsTyping(false);
      }
    }
  };

  const [showSubModal, setShowSubModal] = useState(false);

  const handleShowSubModal = () => {
    navigate('/membership')
  };

  const chatOptions = ['🧹 Clear Chat', '🔗 Share Chat'];
  const [selectedChatOption, setSelectedChatOption] = useState('Start New Chat');
  const [showClearConfirmModal, setShowClearConfirmModal] = useState(false);

  async function handleSetSelectedChatOption(option) {
    if(user.subscriptionStatus == 'inactive') {
      toast.info('Free users can not use these options');
      return;
    };


    if (option === '🧹 Clear Chat') {
      setShowClearConfirmModal(true);
    } else {
      toast.info('That feature is otw 😋');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const newUserMessage = e.target.value.trim();
      if (newUserMessage) {
        // Create a synthetic event object
        const syntheticEvent = {
          preventDefault: () => { },
          target: {
            elements: {
              messageInput: e.target
            }
          }
        };
        handleSendMessage(syntheticEvent);
      }
    }
  };

  const handleClearChat = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await axiosRequest('post', `chats/${chatId}/clear`, null, jwt);

      if (response.data.firstMessage) {
        setMessages([response.data.firstMessage]);
        toast.success('Chat cleared successfully');
      } else {
        toast.error('Error clearing chat');
      }
    } catch (error) {
      console.error('Error clearing chat:', error);
      toast.error('Error clearing chat');
    }
    setShowClearConfirmModal(false);
  };

  const personas = useSelector(state => state.userData.personas || []);
  const [selectedPersona, setSelectedPersona] = useState(null);

  useEffect(() => {
    // Set the default persona if available
    const defaultPersona = personas.find(p => p.default);
    if (defaultPersona) {
      setSelectedPersona(defaultPersona);
    }
  }, [personas]);


  if (loading) return <DashboardLayout title="Loading..."><div>Loading chat...</div></DashboardLayout>;
  if (error) return <DashboardLayout title="Error"><div>{error}</div></DashboardLayout>;
  if (!character) return <DashboardLayout title="Not Found"><div>Character not found.</div></DashboardLayout>;

  return (
    <>
      <DashboardLayout hideFooter={true} title={''} disregardHeight={true} headerStyle={{ padding: '0px', marginBottom: '0px' }}>
        <div className={styles.chatPageContainer}>
          <div className={styles.chatContainer}>
            <div className={styles.header}>
              <div className={styles.headerContent}>
                <img src={updateImageUrl(character.imageUrl)} alt={character.name} className={styles.characterImage} />
                <div className={styles.characterInfo}>
                  <h2
                    onClick={e => {
                      e.preventDefault();
                      navigate(`/character/${character['_id']}`)
                    }}
                  >{character.name}</h2>
                  <p
                    onClick={e => {
                      e.preventDefault();
                      navigate(`/creator/${character.creator.username}`)
                    }}
                  >Created by {character.creator.username}</p>
                </div>
              </div>
              <div className={styles.disclaimerDropdown}>
                <div className={styles.disclaimerToggle} onClick={toggleDropdown}>
                  <span className={styles.disclaimerIcon}>ⓘ</span>
                  <span className={styles.disclaimerText}>Read our safe chat disclaimers</span>
                  <span className={styles.expandIcon}>{isOpen ? '▲' : '▼'}</span>
                </div>
                {isOpen && (
                  <div className={styles.disclaimerContent}>
                    <p>Remember: You're entering the realm of AI-driven, fictional roleplay, where every scenario unfolds with explicit consent. Treat this as a creative space, detached from reality. Engaging here means you commit to our safe chat rules and legal boundaries, avoiding any forbidden topics. Dive into your story, safely and imaginatively!</p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.chatBoxWrapper}>
              <div className={styles.chatBox} ref={chatBoxRef}>
                {isTyping && (
                  <div key={messages.length - 1} className={`${styles.message} ${styles.character}`}>
                    <img src={updateImageUrl(character.imageUrl)} alt={character.name} className={styles.messageAvatar} />
                    <div className={styles.messageContent}>
                      <p>Typing...</p>
                    </div>
                  </div>
                )}
                {newMessage && (
                  <div key={messages.length - 1} className={`${styles.message} ${styles.character}`}>
                    <img src={updateImageUrl(character.imageUrl)} alt={character.name} className={styles.messageAvatar} />
                    <div className={styles.messageContent}>
                      <Typewriter
                        options={{
                          string: newMessage.content,
                          autoStart: true,
                          delay: 22,
                          cursor: '',
                        }}
                        onInit={(typewriter) => {
                          typewriter
                            .typeString(newMessage.content)
                            .callFunction(() => {
                              handleTypingComplete();
                            })
                            .start();
                        }}
                      />
                    </div>
                  </div>
                )}
                {showContinueButton && (
                  <div className={styles.continueButtonWrapper}>
                    <button onClick={handleContinue} className={styles.continueButton}>
                      Continue →
                    </button>
                  </div>
                )}
                {messages.map((message, index) => (
                  <div key={index} className={`${styles.message} ${styles[message.senderType]}`}>
                    {message.senderType === 'character' && (
                      <img src={updateImageUrl(character.imageUrl)} alt={character.name} className={styles.messageAvatar} />
                    )}
                    <div className={styles.messageContent}>
                      <p>{formatMessage(message.content)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.inputArea}>
              <form onSubmit={handleSendMessage}>
                <div className={`${styles.inputRow} ${isExpanded ? styles.expanded : ''}`}>
                  <div className={styles.topInputSection}>
                    <CustomDropdown
                      options={msgOptions}
                      value={msgType}
                      onChange={setMsgType}
                      chat={true}
                    />
                    <CustomDropdown
                      options={personas.map(p => p.name)}
                      value={selectedPersona ? selectedPersona.name : ''}
                      onChange={(name) => setSelectedPersona(personas.find(p => p.name === name))}
                      placeholder="Select Persona"
                      chat={true}
                    />
                    <div className={styles.inputWrapper}>
                      <textarea
                        name="messageInput"
                        placeholder="Type a message..."
                        className={styles.messageInput}
                        rows={isExpanded ? "3" : "1"}
                        onKeyDown={handleKeyDown}
                      />
                      <button type="button" onClick={toggleExpand} className={styles.expandButton}>
                        {'\u26F6'}
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.bottomInputSection}>
                  <div className={styles.leftControls}>
                    <CustomDropdown
                      options={chatOptions}
                      value={'🛠️'}
                      onChange={handleSetSelectedChatOption}
                      chat={true}
                      removeArrow
                      emojiSelector
                    />
                    <LabeledCheckbox
                      id={'nsfw-enabled'}
                      checked={nsfw}
                      onChange={() => setNSFW(!nsfw)}
                    >
                      NSFW
                    </LabeledCheckbox>
                  </div>
                  <div className={styles.rightControls}>
                    <button
                      type="button"
                      className={styles.voiceButton}
                      onClick={handleSetSelectedChatOption}
                    >🎤</button>
                    <button type="submit" className={styles.sendButton}>▶</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>
      <SubscribeModal
        isOpen={showSubModal}
        onClose={() => setShowSubModal(false)}
        onConfirm={handleShowSubModal}
        bgImg={updateImageUrl(character.imageUrl)}
      />
      <ConfirmModal
        isOpen={showClearConfirmModal}
        onClose={() => setShowClearConfirmModal(false)}
        onConfirm={handleClearChat}
        title="Clear Chat"
        message="Are you sure you want to clear this chat? This action cannot be undone."
      />
    </>
  );
}