import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import Spinner from '../components/Spinner/Spinner';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/CreateChar.module.scss';

import axiosRequest from '../utils/request';

import { toast } from 'react-toastify';

import ImageGenerationForm from '../components/ImageGenForm';

export default function CreateChar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [generateMode, setGenerateMode] = useState(true);
  const MemoizedImageGenerationForm = React.memo(ImageGenerationForm);

  const user = useSelector(state => state.userData)

  const [loggedIn, setLoggedIn] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [character, setCharacter] = useState({
    name: '',
    age: '',
    tags: [],
    description: '',
    personality: '',
    firstMessage: '',
    scenario: '',
    exampleConversation: '',
    kinks: '',
    nsfw: false,
    public: true,
  });
  const [imagePreview, setImagePreview] = useState('/portal.gif');

  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    //console.log('LOVATION -> ', location);

    if (location.state && location.state.character) {
      setIsEditMode(true);
      const editCharacter = location.state.character;
      setCharacter(editCharacter);
      if (editCharacter.imageUrl) {
        setImagePreview(editCharacter.imageUrl);
      }
    }
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCharacter({
      ...character,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCharacter({
        ...character,
        image: file
      });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setCharacter({
        ...character,
        image: null
      });
      setImagePreview(null);
    }
  };

  const handleTagChange = (e, index) => {
    let newTags = [...character.tags];
    newTags[index] = e.target.value;
    setCharacter({
      ...character,
      tags: newTags
    });
  };

  const addTag = () => {
    if (character.tags.length < 5) {
      setCharacter({
        ...character,
        tags: [...character.tags, '']
      });
    }
  };

  const removeTag = (index) => {
    const newTags = character.tags.filter((_, i) => i !== index);
    setCharacter({
      ...character,
      tags: newTags
    });
  };

  async function handleSubmit() {
    try {
      setIsLoading(true);

      let jwt = localStorage.getItem('jwt');
      if (!jwt) {
        throw new Error('No authentication token found');
      }

      const formData = new FormData();

      // Append all character data to formData
      Object.keys(character).forEach(key => {
        if (key !== 'image') {
          formData.append(key, typeof character[key] === 'object' ? JSON.stringify(character[key]) : character[key]);
        }
      });

      if (!generateMode && !character.image) {
        toast.error("Please upload an image or select generate mode for the character's image!");
        setIsLoading(false);
        return;
      }

      // Append image if it exists and not in generate mode
      if (!generateMode && character.image) {
        formData.append('image', character.image, character.image.name);
      }

      formData.append('generateMode', generateMode);

      // If in generate mode, append image generation data
      if (generateMode) {
        Object.keys(imageGenerationData).forEach(key => {
          formData.append(`imageGeneration[${key}]`, imageGenerationData[key]);
        });

        // Remove the existing 'age' field if it exists
        formData.delete('age');

        // Set the new 'age' value
        formData.set('age', imageGenerationData['age']);
      }

      console.log('Form Data -> ', formData);

      const endpoint = isEditMode ? `characters/${character._id}` : 'create';
      const method = isEditMode ? 'put' : 'post';

      const result = await axiosRequest(method, endpoint, formData, jwt, true);

      if (result.data && result.data.character) {
        toast.success(isEditMode ? 'Character updated successfully!' : 'Character created successfully!');
        setIsLoading(false);
        setTimeout(() => {
          navigate(`/character/${result.data.character._id}`);
        }, 1000);
      } else {
        toast.error(isEditMode ? 'Character update failed!' : 'Character creation failed!');
        setIsLoading(false);
      }
    } catch (e) {
      console.error('Error @ handleSubmit -> ', e);
      toast.error(e.response?.data?.message || `Error ${isEditMode ? 'updating' : 'creating'} character. Please try again.`);
      setIsLoading(false);
    }
  }

  const handleGenerateImage = () => {
    if (generateMode) {
      setGenerateMode(false);
      setImagePreview('');
    } else {
      setGenerateMode(true);
      setImagePreview('/portal.gif');
    }
  };

  const [imageGenerationData, setImageGenerationData] = useState({
    gender: '',
    ethnicity: '',
    age: '',
    hairColor: '',
    face: '',
    pose: '',
    breastSize: '',
    bodyType: '',
    prompt: '',
    style: ''
  });

  const handleImageGenerationChange = (field, value) => {
    setImageGenerationData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  return (
    <>
      {!loggedIn ? (
        <></>
      ) : (
        <DashboardLayout title={isEditMode ? 'Edit Character' : 'Create Character'}>
          <div className={styles.formContainer}>
            {(!user ? true : (user.subscriptionStatus === 'inactive' ? true : false)) && (
              <div className={styles.premiumOverlay}>
                <div className={styles.premiumContent}>
                  <p><span role="img" aria-label="diamond">💎</span> Sign up to Premium to create characters</p>
                  <p><small>Free members can not create characters. Subscribe to continue.</small></p>
                  <button onClick={() => navigate('/membership')} className={styles.subscribeButton}>
                    Subscribe
                  </button>
                </div>
              </div>
            )}
            <form className={`${styles.form} ${user ? (user.subscriptionStatus === 'inactive' ? styles.blurred : '') : styles.blurred}`}>
              <div className={styles.formGroup}>
                <label>Character Name</label>
                <input
                  type="text"
                  name="name"
                  value={character.name}
                  onChange={handleInputChange}
                />
                <small>This is your character's display name.</small>
              </div>

              <div className={styles.formGroup}>
                <label>Character Image</label>
                <div className={styles.row}>
                  <div className={styles.imagePreview}>
                    {imagePreview ? (
                      <div className={styles.imageContainer}>
                        <img src={imagePreview} alt="Character Preview" className={styles.previewImage} />
                        {generateMode && (
                          <div className={styles.generateOverlay}>
                            <span className={styles.generateText}>Generate Mode</span>
                            <span className={styles.generateSubText}>Image generation will be queued.</span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={styles.placeholderImage}>
                        <span>?</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.column}>
                    {/*<input
                      type="file"
                      name="image"
                      onChange={handleFileChange}
                      style={{ margin: '10px' }}
                      disabled={generateMode}
                    />
                    <b style={{ fontSize: '18px', fontWeight: 'bold', color: '#fff' }}>or</b>*/}
                    <button
                      style={{ marginTop: '5px', fontWeight: 'bold' }}
                      type="button"
                      onClick={e =>{
                        e.preventDefault();
                        //handleGenerateImage()
                      }}
                    >
                      {generateMode ? '✅' : ''} Generate Image
                    </button>
                  </div>
                </div>
                <small>Upload an image that represents your character. Make sure these images comply with our terms and community guidelines.</small>
              </div>

              {generateMode && (
                <>
                  <MemoizedImageGenerationForm
                    key="imageGenerationForm"
                    imageGenerationData={imageGenerationData}
                    handleImageGenerationChange={handleImageGenerationChange}
                  />
                  <div className={styles.formGroup}>
                    <label style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Prompt</label>
                    <textarea
                      name="prompt"
                      value={imageGenerationData.prompt || ''}
                      onChange={(e) => handleImageGenerationChange('prompt', e.target.value)}
                      placeholder="tight black polo, tight black jeans, heels, bent over in doctors office"
                    />
                  </div>
                </>
              )}

              {!generateMode && (
                <div className={styles.formGroup}>
                  <label>Character Age</label>
                  <input
                    type="number"
                    name="age"
                    value={character.age}
                    onChange={handleInputChange}
                    min="18"
                  />
                  <small>This is your character's age. Minimum age is 18.</small>
                </div>
              )}
              <div className={styles.formGroup}>
                <label>Character Tags</label>
                {character.tags.map((tag, index) => (
                  <div key={index} className={styles.tagInput}>
                    <input
                      type="text"
                      value={tag}
                      onChange={(e) => handleTagChange(e, index)}
                    />
                    <button type="button" onClick={() => removeTag(index)}>Remove</button>
                  </div>
                ))}
                <button style={{ marginLeft: '14px' }} type="button" onClick={addTag}>+ Add tag</button>
                <small>Assign tags that describe your character. You can add a maximum of 5 tags.</small>
              </div>
              <div className={styles.formGroup}>
                <label>Description</label>
                <textarea
                  name="description"
                  value={character.description}
                  onChange={handleInputChange}
                  placeholder='A mysterious and elusive succubus, tasked by the Succubus High Council to seduce and corrupt mortal men for their own twisted amusement. With unearthly beauty, cunning, and supernatural allure, she is a master of seduction, luring men into her clutches with promises of forbidden pleasure.'
                />
                <small>Write a brief overview of your character.</small>
              </div>
              <div className={styles.formGroup}>
                <label>Personality</label>
                <textarea
                  name="personality"
                  value={character.personality}
                  onChange={handleInputChange}
                  placeholder='Manipulative, flirtatious, sadistic'
                />
                <small>Describe your character's traits, behavior, and demeanor.</small>
              </div>
              <div className={styles.formGroup}>
                <label>First Message</label>
                <textarea
                  name="firstMessage"
                  value={character.firstMessage}
                  onChange={handleInputChange}
                  placeholder={`"The night air feels so much better when you're by my side." Raven purrs, her sultry voice weaving a spell of lust and submission. "Shall we take a stroll, darling, and see where the shadows lead us?" She takes your hand, her touch electric, and gazes at you with eyes that promise both ecstasy and torment.`}
                />
                <small>Write the first message your character will send.</small>
              </div>
              <div className={styles.formGroup}>
                <label>Kinks</label>
                <textarea
                  name="kinks"
                  value={character.kinks}
                  onChange={handleInputChange}
                  placeholder={`public humiliation, orgasm denial, mind games, domination`}
                />
                <small>Describe any specific kinks or preferences of your character.</small>
              </div>
              <div className={styles.formGroup}>
                <label>
                  <input
                    type="checkbox"
                    name="nsfw"
                    checked={character.nsfw}
                    onChange={handleInputChange}
                  />
                  NSFW
                </label>
                <small>Check this if the character is Not Safe For Work.</small>
              </div>
              {/*<div className={styles.formGroup}>
                <label>
                  <input
                    type="checkbox"
                    name="public"
                    checked={character.public}
                    onChange={handleInputChange}
                  />
                  Public
                </label>
                <small>Check this if you want the character to be publicly visible.</small>
              </div> */}

              <div className={styles.formGroup}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (!loading) {
                      handleSubmit();
                    }
                  }}
                  type="submit"
                  className={styles.submitButton}
                >
                  {loading ? <Spinner /> : isEditMode ? 'Update' : 'Create'}
                </button>
              </div>
            </form>
          </div>
        </DashboardLayout>
      )}
    </>
  );
}

